.btn-custom {
    border-radius: unset;
    background-color: white ;
    color: var(--primary-color);
    border: solid 1px var(--primary-color);
    border-radius: unset;
}

.btn-custom span{
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    .btn-custom {
        font-size: 14px !important;
    }
}

.btn-custom.active:focus {
    background-color: var(--primary-color) ;
    color: white !important;
}

.btn-custom.active {
    background-color: var(--primary-color) !important;
    color: white;
}