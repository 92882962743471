:root {
    --primary-color: #8a6025;
    --primary-color-50: rgba(138, 96, 37, 0.5);
    --primary-color-80: rgba(138, 96, 37, 0.8);
    --secondary-color: #fafafb;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.ant-scroll-number-only-unit {
    font-size: 20px !important;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.app-header .nav {
    width: 100%;
}

.app-header .app-header-wrapper {
    justify-content: space-around;
}

.app-header .app-header-wrapper .logo-restaurant span {
    color: #fff;
    line-height: 4.5;
    white-space: nowrap;
}

.app-header .app-header-wrapper .logo-restaurant {
    display: flex;
}

.table-list-container .table-cover__code .wrapper {
    position: absolute;
    top: 10%;
    z-index: 101;
}

.table-card-content-active {
    background-color: rgba(62, 73, 84, 1) !important;
}

.ant-card .ant-card-body .icon-customer {
    scale: 1.4;
    margin-right: 4px;
}

@media screen and (min-width: 668px) {
    .ant-card .ant-card-body .icon-customer {
        scale: 1.5;
        margin-right: 5px;
    }
}

.primary-color span {
    color: var(--primary-color) !important;
}

.table-card-extra .ant-card-head-title,
.table-card-extra-is-used .ant-card-head-title {
    font-size: 24px;
    padding-top: 50% !important;
}

.table-card-extra-is-used {
    background: var(--primary-color) !important;
    color: #fff !important;
    position: relative;
}

.table-card-extra .ant-card-body .ant-row:first-child .ant-col-10:first-child,
.table-card-extra-is-used .ant-card-body .ant-row:first-child .ant-col-10:first-child {
    display: flex;
}

.table-card-extra .ant-card-body .ant-row:first-child .ant-col-10:first-child h3,
.table-card-extra-is-used .ant-card-body .ant-row:first-child .ant-col-10:first-child h3 {
    line-height: 1;
    color: white;
}

.table-card-extra .ant-card-body .ant-row:first-child .ant-col-14:nth-child(2),
.table-card-extra-is-used .ant-card-body .ant-row:first-child .ant-col-14:nth-child(2) {
    display: flex;
    justify-content: end;
}

.table-card-extra .ant-card-body .ant-row:first-child .ant-col-14:nth-child(2) h5,
.table-card-extra-is-used .ant-card-body .ant-row:first-child .ant-col-14:nth-child(2) h5 {
    color: white;
}

.table-card-extra .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2,
.table-card-extra-is-used .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2 {
    color: white;
    padding-top: 20%;
}

@media only screen and (min-width: 420px) {
    .table-card-extra .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2,
    .table-card-extra-is-used .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2 {
        padding-top: 27%;
    }
}

@media only screen and (min-width: 520px) {
    .table-card-extra .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2,
    .table-card-extra-is-used .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2 {
        padding-top: 30%;
    }
}

@media only screen and (min-width: 576px) {
    .table-card-extra .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2,
    .table-card-extra-is-used .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2 {
        padding-top: 15%;
    }
}

@media only screen and (min-width: 768px) {
    .table-card-extra .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2,
    .table-card-extra-is-used .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2 {
        padding-top: 20%;
    }
}

@media only screen and (min-width: 992px) {
    .table-card-extra .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2,
    .table-card-extra-is-used .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2 {
        padding-top: 30%;
    }
}

@media only screen and (min-width: 1670px) {
    .table-card-extra .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2,
    .table-card-extra-is-used .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2 {
        padding-top: 39%;
    }
}

.table-card-extra .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2,
.table-card-extra-is-used .ant-card-body .ant-row:nth-child(2) .ant-col-24:first-child h2 {
    color: white;
}

.table-card-extra {
    background: #cfcccc !important;
    color: #fff !important;
}

.table-card-content-inactive {
    display: none;
    background-color: #eff0fa !important;
}

.table-card-extra-inactive {
    opacity: 0.7;
    background-color: #dddddd !important;
}

.modal-confirm-input-number-people .ant-modal-header {
    border-bottom: unset;
}

.modal-confirm-input-number-people__title {
    display: block;
    width: 100%;
    text-align: center;
}

.modal-confirm-input-number-people__title .language-button {
    display: block;
    margin: 0 auto;
    width: 100% !important;
}

.modal-confirm-input-number-people__title .language-button .ant-select {
    width: 94% !important;
}

.modal-confirm-input-number-people__title .language-button .ant-select .ant-select-selector {
    border-radius: 2rem !important;
}

.modal-confirm-input-number-people__title .language-button .ant-select-selector {
    border: 2px solid var(--primary-color) !important;
}

.modal-confirm-input-number-people .ant-modal-content {
    border-radius: unset;
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-header .ant-modal-title {
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: black;
    font-size: 18px;
}

@media screen and (min-width: 768px) {
    .modal-confirm-input-number-people .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 22px;
    }
}

.modal-footer-remind-action {
    top: 30% !important;
}

.modal-error-wrapper .ant-modal-content h3,
.modal-food-invalid-value-wrapper .ant-modal-content h3,
.modal-status-complete .ant-modal-content .ant-modal-body h3,
.modal-footer-call-service .ant-modal-content .ant-modal-body h3,
.modal-footer-call-pay-bill .ant-modal-content .ant-modal-body h3,
.modal-footer-remind-action .ant-modal-content .ant-modal-body h3 {
    font-weight: 600;
    color: black;
    font-size: 18px;
}

@media screen and (min-width: 768px) {
    .modal-error-wrapper .ant-modal-content h3,
    .modal-food-invalid-value-wrapper .ant-modal-content h3,
    .modal-status-complete .ant-modal-content .ant-modal-body h3,
    .modal-footer-call-service .ant-modal-content .ant-modal-body h3,
    .modal-footer-call-pay-bill .ant-modal-content .ant-modal-body h3,
    .modal-footer-remind-action .ant-modal-content .ant-modal-body h3 {
        font-size: 22px;
    }
}

.modal-error-wrapper .ant-modal-content,
.modal-hodai-time-wrapper .ant-modal-content,
.modal-food-invalid-value-wrapper .ant-modal-content,
.modal-status-complete .ant-modal-content,
.modal-footer-call-service .ant-modal-content,
.modal-footer-call-pay-bill .ant-modal-content,
.modal-footer-remind-action .ant-modal-content {
    border-radius: unset;
}

.ant-modal-content .ant-modal-close {
    pointer-events: none;
}

.ant-modal-footer button:last-child:hover {
    background-color: #ededed !important;
}

.modal-error-wrapper .ant-modal-content .ant-modal-footer,
.modal-hodai-time-wrapper .ant-modal-content .ant-modal-footer,
.modal-food-invalid-value-wrapper .ant-modal-content .ant-modal-footer,
.modal-status-complete .ant-modal-content .ant-modal-footer,
.modal-confirm-order-wrapper .ant-modal-content .ant-modal-footer,
.modal-footer-call-service .ant-modal-content .ant-modal-footer,
.modal-footer-call-pay-bill .ant-modal-content .ant-modal-footer,
.modal-footer-remind-action .ant-modal-content .ant-modal-footer {
    border-top: unset;
    border-radius: unset;
    padding: 0;
}

.modal-food-invalid-value-wrapper .ant-modal-content .ant-modal-footer,
.modal-status-complete .ant-modal-content .ant-modal-footer {
    justify-content: end;
}

.modal-error-wrapper .ant-modal-content .ant-modal-footer button,
.modal-hodai-time-wrapper .ant-modal-content .ant-modal-footer button,
.modal-food-invalid-value-wrapper .ant-modal-content .ant-modal-footer button,
.modal-status-complete .ant-modal-content .ant-modal-footer button,
.modal-footer-call-service .ant-modal-content .ant-modal-footer button,
.modal-footer-call-pay-bill .ant-modal-content .ant-modal-footer button,
.modal-footer-remind-action .ant-modal-content .ant-modal-footer button {
    letter-spacing: 2px;
    font-weight: 600;
    min-width: 150px;
    font-size: 16px;
    line-height: 1;
}

@media screen and (min-width: 768px) {
    .modal-error-wrapper .ant-modal-content .ant-modal-footer button,
    .modal-hodai-time-wrapper .ant-modal-content .ant-modal-footer button,
    .modal-food-invalid-value-wrapper .ant-modal-content .ant-modal-footer button,
    .modal-status-complete .ant-modal-content .ant-modal-footer button,
    .modal-footer-call-service .ant-modal-content .ant-modal-footer button,
    .modal-footer-call-pay-bill .ant-modal-content .ant-modal-footer button,
    .modal-footer-remind-action .ant-modal-content .ant-modal-footer button {
        font-size: 20px;
    }
}

.modal-footer-call-pay-bill .ant-modal-footer,
.modal-footer-call-service .ant-modal-footer {
    padding: 10px 10px;
}

.modal-confirm-order-wrapper .modal-confirm-order {
    line-height: 3rem;
}

.modal-confirm-order-wrapper .ant-modal-content {
    border-radius: unset;
}

.modal-confirm-order-wrapper .modal-confirm-order .icon-modal-confirm-order {
    display: block;
    /* color: var(--primary-color); */
    font-size: 3rem;
}

.modal-confirm-order-wrapper .modal-confirm-order .icon-modal-confirm-order svg path {
    fill: orange !important;
}

.modal-confirm-order-wrapper .modal-confirm-order .text-modal-confirm-order {
    display: block;
    text-align: center;
}

.modal-confirm-order-wrapper .modal-confirm-order .text-modal-confirm-order.text-1 {
    font-size: 1rem;
    font-weight: bold;
}

.modal-confirm-order-wrapper .modal-confirm-order .text-modal-confirm-order.text-3 {
    font-size: 0.75rem;
    color: #ccc;
    font-weight: bold;
}

.modal-error-wrapper .ant-modal-content .ant-modal-footer,
.modal-confirm-order-wrapper .ant-modal-content .ant-modal-footer {
    justify-content: end;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-header {
    border-radius: unset;
    border-bottom: unset;
    text-align: center;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body {
    padding: 16px 24px;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-name {
    font-weight: 600;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-alert {
    font-weight: 600;
    text-align: center;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-start-time,
.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-totalTime,
.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-remain {
    display: flex;
    justify-content: center;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-start-time span,
.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-totalTime span,
.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-remain span {
    color: black;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-remain {
    font-weight: 600;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-name span {
    color: black;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-alert span {
    color: black;
    font-size: 22px;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-alert .modal-hodai-time-alert--line-1 {
    color: var(--primary-color);
}

@media only screen and (min-width: 768px) {
    .modal-hodai-time-wrapper .ant-modal-content .ant-modal-body .modal-hodai-time-alert span {
        color: black;
        font-size: 40px;
    }
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-header .ant-modal-title {
    color: var(--primary-color);
    font-weight: 600;
}

.modal-hodai-time-wrapper .ant-modal-content .ant-modal-footer {
    justify-content: end;
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer {
    padding: 0 30px 16px 30px;
    border-top: unset;
    margin-top: 16px;
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer .ant-input,
.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer .ant-input-number-input {
    text-align: center;
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer .ant-input-number .ant-input-number-handler-wrap {
    display: none !important;
}

@media only screen and (min-width: 375px) {
    .modal-confirm-input-number-people .ant-modal-content .ant-modal-footer {
        padding: 0 18px 16px 18px;
    }
}

@media only screen and (min-width: 390px) {
    .modal-confirm-input-number-people .ant-modal-content .ant-modal-footer {
        padding: 0 20px 16px 20px;
    }
}

@media only screen and (min-width: 460px) {
    .modal-confirm-input-number-people .ant-modal-content .ant-modal-footer {
        padding: 0 30px 16px 30px;
    }
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer button {
    min-width: 120px;
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer .ant-input-number {
    width: 52px;
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer button:first-child {
    border-radius: unset;
    background-color: var(--primary-color);
    color: white;
    border-color: unset;
}

/* Dropdown styles */
.dropdown {
    width: 100%;
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    align-items: center;
    padding: 12px;
    background-color: #ffffff;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease;
    border-radius: 3em;
}

.dropdown-toggle:hover {
    background-color: #f8f8f8;
}

.dropdown-option-image {
    position: absolute;
    top: -0.2em;
    right: -2px;
    width: 30px;
    height: 30px;
}

.dropdown-option-images {
    width: 30px;
    height: 30px;
}

.dropdown-option-label {
    font-weight: bold;
    width: 40%;
    text-align: center;
}

.dropdown-option-label__col-2 {
    font-weight: bold;
    width: 100%;
    text-align: center;
}

.dropdown-caret {
    position: absolute;
    right: 0;
    display: inline-block;
    margin-left: 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: var(--primary-color) transparent transparent transparent;
}

.dropdown-menu {
    width: 100%;
    position: absolute;
    top: 100%;
    right: 50%;
    transform: translateX(50%);
    margin: 0;
    padding: 12px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    list-style: none;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 4px;
}

.dropdown-menu.open {
    opacity: 1;
    visibility: visible;
}

.dropdown-menu li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.dropdown-menu li:hover {
    background-color: #f8f8f8;
}

.dropdown-menu li .dropdown-option-image {
    margin-right: 12px;
}

.dropdown-menu li .dropdown-option-label {
    font-weight: bold;
}

/* Centering the dropdown */
.dropdown.open .dropdown-menu {
    transform: translateY(0);
    border-radius: 0 0 4px 4px;
    border-top: none;
}

/* Adjust dropdown width */
.dropdown.open .dropdown-toggle {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer h3 {
    margin: 0 !important;
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer {
    align-items: center;
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer button:first-child span {
    font-weight: 600;
}

.ant-btn {
    /* border-radius: 30px !important; */
    color: white !important;
}

.modal-confirm-input-number-people .ant-btn {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
}

@media only screen and (max-width: 460px) {
    .modal-confirm-input-number-people .ant-modal-content .ant-modal-footer button:first-child span,
  /* .dropdown-option-label, */
  .modal-confirm-input-number-people .ant-modal-content .ant-modal-footer button:nth-child(3) span {
        font-size: 0.8em;
    }
}

@media only screen and (min-width: 576px) {
    .dropdown-option-label__col-2 {
        text-align: center;
    }
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer button:nth-child(3) {
    border-radius: unset;
    background-color: var(--primary-color);
    color: white;
    border-color: unset;
}

.modal-confirm-input-number-people .ant-modal-content .ant-modal-footer button:nth-child(3) span {
    font-weight: 600;
}

.modal-confirm-input-number-people .ant-modal-body {
    padding: 0px 5px 0px 0px;
}

.modal-confirm-input-number-people .ant-modal-body .btn-custom {
    background: rgba(138, 96, 37, 0.5);
    color: white;
    font-weight: 600;
    width: 100%;
    border: unset;
}

.modal-confirm-input-number-people .ant-modal-body .btn-custom.active:focus {
    background-color: var(--primary-color) !important;
}

.modal-footer-order .modal-footer-order-row {
    font-size: 16px;
}

.modal-footer-order .modal-footer-order-row .text-align {
    text-align: right;
}

.modal-footer-order .modal-footer-order-btn {
    margin-top: 30px;
}

.modal-footer-order .modal-footer-order-btn .btn-secondary {
    background-color: #fff;
    color: orange;
    border: 1px solid orange;
}

.ant-modal-wrap {
    overflow: hidden !important;
}

.modal-food-order-container img {
    width: 100% !important;
    height: auto !important;
}

.modal-food-order-container .lazy-load-image-background {
    margin-right: 15px;
}

.modal-food-order-container .lazy-load-image-background img {
    width: 100% !important;
    height: auto !important;
}

.modal-food-order-container .card-food-img {
    background-size: cover;
    background-position: center;
    height: 100%;
    margin-right: 15px;
    width: 100%;
}

.modal-food-order-container .card-food-img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
}

.modal-food-order-container .modal-food-order-name {
    font-size: 16px;
    font-weight: 600;
    color: black !important;
}

.modal-food-order-container .modal-food-order-time {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: black !important;
}

.modal-food-order-container .modal-food-order-unit {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: black !important;
}

.modal-food-order-container .modal-food-order-quantity {
    width: 4%;
    padding: 4%;
    height: 0;
}

.modal-food-order-container .modal-food-order-description {
    color: black !important;
}

.modal-food-order-container .modal-food-order-quantity.quantity-customer {
    width: 4%;
    padding: 4%;
    height: 0;
    background-color: var(--primary-color);
    border-radius: 4px;
}

.modal-food-order-container .modal-food-order-quantity span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--primary-color);
}

.modal-food-order-container .modal-food-order-quantity.quantity-customer span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: white;
}

.modal-food-order-container .modal-food-order-total {
    display: flex;
    align-items: center;
    justify-content: end;
    font-weight: 600;
    color: black !important;
}

.modal-food-order-container .modal-food-order-delete-icon {
    display: flex;
    align-items: center;
    justify-content: end;
}

@media screen and (max-width: 468px) {
    .modal-food-order-container .modal-food-order-minus,
    .modal-food-order-container .modal-food-order-plus {
        position: relative;
        top: 2px;
    }

    .modal-food-order-container .modal-food-order-plus {
        position: relative;
        left: 5px;
    }

    .modal-food-order-container .modal-food-order-minus svg {
        width: 80%;
        height: 80%;
    }

    .modal-food-order-container .modal-food-order-plus svg {
        width: 80%;
        height: 80%;
    }
}

.modal-food-order-wrapper .ant-modal-content {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: unset;
    padding: unset !important;
    margin-top: 1px;
}

.modal-food-order-wrapper .modal-card-container {
    color: black;
    align-items: center;
}

.modal-food-order-wrapper .modal-card-container > div:first-child {
    padding-right: 10px;
}

.new-cart-drawer__wrapper .ant-drawer-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.modal-food-order-wrapper .modal-card-container .img,
.new-cart-drawer__wrapper .modal-card-container .img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    min-height: 70px;
    margin-right: 10px !important;
}

.modal-food-order-wrapper .modal-card-container .modal-card-name {
    font-weight: 600;
}

.modal-food-order-wrapper .modal-card-container .modal-card-quantity {
    width: 30px;
    padding-top: 5px;
    display: flex;
    position: relative;
    text-align: center;
    font-weight: 600;
}

@media screen and (min-width: 768px) {
    .modal-food-order-wrapper .modal-card-container .modal-card-quantity {
        width: 60px;
    }
}

.modal-food-order-wrapper .modal-card-container .modal-card-quantity span {
    text-align: center;
    margin: 0 auto;
}

.modal-food-order-wrapper .modal-card-container .modal-card-price {
    font-weight: 600;
    margin: 5px 0px;
}

.modal-food-order-wrapper .ant-modal-content .card-food-img {
    height: 100%;
}

.modal-food-order-wrapper .ant-modal-content .ant-modal-footer button {
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
}

@media screen and (min-width: 768px) {
    .modal-food-order-wrapper .ant-modal-content .ant-modal-footer button {
        font-size: 20px;
    }
}

.modal-food-order-wrapper .ant-modal-content .ant-modal-body {
    padding: unset;
}

.modal-food-order-wrapper .ant-modal-content .ant-modal-body h3 {
    font-weight: 600;
    color: black;
    margin-top: 5px;
    font-size: 18px;
}

@media screen and (min-width: 768px) {
    .modal-food-order-wrapper .ant-modal-content .ant-modal-body h3 {
        font-size: 22px;
    }
}

.modal-card-food-name,
.modal-card-food-description {
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: black !important;
    letter-spacing: 2px;
    margin-top: 2px;
}

.new-modal-card-food-name,
.new-modal-card-food-description {
    display: flex;
    text-align: start;
    justify-content: start;
    font-size: 19px;
    font-weight: 600;
    color: black !important;
    letter-spacing: 2px;
    margin-top: 2px;
}

.new-modal-food-order-price {
    font-size: 22px;
    justify-content: end;
    display: flex;
    color: black !important;
}

.new-modal-food-order-price__wrapper span {
    color: orange !important;
}

.new-modal-food-order-price__wrapper svg > path {
    fill: red !important;
}

.new-modal-food-order-option {
    width: 100%;
}

.new-option-food-group {
    width: 100%;
}

.modal-food-order-wrapper .ant-modal-content .ant-modal-body .modal-food-order-wrapper .ant-modal-content .ant-modal-body {
    display: flex;
    text-align: right;
    justify-content: end;
    font-size: 16px;
    font-weight: 600;
    color: black;
    letter-spacing: 2px;
    margin-top: 2px;
}

.modal-food-order-wrapper .ant-modal-content .ant-modal-body .modal-card-food-unit {
    display: flex;
    justify-content: end;
    font-size: 16px;
    font-weight: 600;
    color: black;
    margin-top: 2px;
}

.modal-food-order-wrapper .modal-food-order-name {
    font-weight: bold;
    font-size: 18px;
    color: #333;
    align-items: center !important;
}

.modal-food-order-wrapper .card-food-img {
    border-radius: unset;
}

.modal-food-order-wrapper .modal-food-order-price {
    font-size: 22px;
    color: var(--primary-color);
}

.modal-food-order-wrapper .modal-food-order-option {
    flex-direction: column;
}

.modal-food-order-wrapper .ant-modal-content .ant-modal-footer {
    padding: 0;
    border-top: unset;
}

.modal-food-order-wrapper .ant-modal-content .ant-modal-footer button {
    min-width: 150px;
}

.menu-button-margin {
    margin: 0.4rem 0.4rem 0.4rem 0rem;
}

.menu-top-text {
    line-height: 20px;
    font-size: 18px;
}

.card-food-name-style {
    color: #333;
    font-weight: bold;
    text-align: left;
}

.card-food-desc-style {
    color: #808080;
    text-align: left;
}

.card-food-price {
    font-weight: bold;
    text-align: left;
}

.card-food-currency {
    color: #f2621c;
    margin-right: 0.5rem;
}

.nav-center {
    text-align: center !important;
}

.card-food-order-button {
    border: solid 0.1px orange !important;
}

.nav-category-bar {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    background-color: var(--secondary-color) !important;
}

.nav-category-bar.nav-category-bar-parent {
    z-index: 997;
    background-color: #fff;
    position: fixed;
    margin: unset;
    top: 0;
    left: 0;
    width: 100%;
}

.nav-category-bar.nav-category-bar-parent .ant-btn {
    border-radius: unset;
    margin: 0;
    padding: 8.5px 2.25rem !important;
}

.nav-category-bar.nav-category-bar-parent .ant-btn span {
    font-size: 20px;
}

.nav-category-bar.nav-category-bar-child .ant-btn {
    border-radius: unset;
    margin: 0;
    position: relative;
    background-color: transparent !important;
    padding: 5px 10px !important;
}

.nav-category-bar.nav-category-bar-child {
    z-index: 10;
    background-color: #fff;
    position: fixed;
    padding-right: 20px;
    top: 45px;
    left: 0;
    width: 100%;
}

::-webkit-scrollbar {
    display: none;
}

.nav-category-bar .is-buffet {
    display: none;
}

.nav-category-bar .active {
    background-color: var(--primary-color) !important;
    color: white;
    pointer-events: none;
    font-weight: 600;
}

.nav-category-bar .inactive {
    background-color: #cfcccc !important;
}

.nav-category-bar .inactive span {
    color: black;
    font-weight: 600;
}

.nav-category-bar.nav-category-bar-child {
    padding: 0 5px;
    padding-top: 4px;
}

.nav-category-bar.nav-category-bar-parent .ant-btn,
.nav-category-bar.nav-category-bar-child .ant-btn {
    margin: 0 1px;
}

.nav-category-bar.nav-category-bar-child .ant-btn:last-child:after {
    display: none;
}

.nav-category-bar.nav-category-bar-child .ant-btn:first-child {
    border-left: unset;
}

.nav-category-bar.nav-category-bar-child .ant-btn:last-child {
    border-right: unset;
}

.nav-category-bar .child-active {
    background-color: var(--primary-color-80) !important;
}

.nav-category-bar .child-active span {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.nav-category-bar .child-inactive {
    background-color: var(--primary-color-50) !important;
}

.nav-category-bar .child-inactive span {
    color: black !important;
    font-weight: 600;
    font-size: 16px;
}

.nav-category-bar .active span {
    color: white;
}

.option-food-container {
    margin-top: 0.7rem;
}

.option-food-container .option-food-name,
.option-food-container .option-food-price {
    font-weight: 600;
    font-size: 18px;
    color: black !important;
}

.option-food-container .option-food-price {
    display: flex;
    justify-content: end;
}

.option-food-container .option-food-price {
    color: var(--primary-color);
}

.option-food-container .radio-group-type .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: unset !important;
}

.option-food-container .radio-group-type .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: unset;
}

.option-food-container .radio-group-type .ant-radio-button-wrapper:hover {
    color: var(--primary-color);
}

.option-food-container .radio-group-type .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: unset !important;
    color: white !important;
}

.option-food-container .radio-group-type .ant-radio-button-wrapper-checked {
    background-color: var(--primary-color);
    color: white;
}

.option-food-container .radio-group-type label {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border-radius: unset;
    background-color: white;
    color: var(--primary-color);
    border: solid 1px var(--primary-color);
    min-width: 150px;
    text-align: center;
}

.ant-input-number-sm input {
    text-align: center;
    font-weight: bold;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: orange !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: orange !important;
    background: rgb(255, 255, 255);
    border-color: orange !important;
}

.ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

.ant-card-body {
    padding: 8px !important;
}

.card-food-img .badge-tag-wrapper {
    position: absolute;
    left: 4px;
    top: 4px;
    z-index: 2;
}

.card-food-img .badge-tag-wrapper .badge-tag {
    position: relative;
    width: 3.5rem;
    background-color: #fff;
    border: 1px solid orange;
    border-radius: 0.25rem;
}

.card-food-img .badge-tag-wrapper .badge-tag .badge-tag-content {
    color: red;
    font-weight: bold;
    font-size: 12px;
    padding: 1px 4px;
    display: inline-block;
}

.table-header-is-customer {
    margin-left: -1rem !important;
}

.nav-center .fix-padding {
    padding: 0 0 !important;
}

.nav-center .fix-padding span {
    font-size: 1rem;
    font-weight: bold;
}

.app-header {
    position: relative;
}

.app-header.footer-menu {
    height: 60px !important;
    line-height: 60px !important;
    z-index: 997 !important;
}

.app-header.new-footer-menu {
    height: 68px !important;
    line-height: 68px !important;
    z-index: 997;
}

.app-header.new-footer-menu p {
    margin-bottom: unset !important;
}

.app-header.new-footer-menu .ant-badge.badge-item-card {
    width: 100% !important;
}

.app-header.footer-menu .ant-badge.badge-item-card .ant-badge-count .ant-scroll-number-only,
.app-header.new-footer-menu .ant-badge.badge-item-card .ant-badge-count .ant-scroll-number-only {
    display: flex !important;
    align-items: center !important;
}

.app-header.footer-menu .ant-badge.badge-item-card {
    width: 100% !important ;
}

.app-header.new-footer-menu .ant-badge.badge-item-card p,
.app-header.footer-menu .ant-badge.badge-item-card p {
    font-size: 14px !important;
    line-height: 1 !important;
}

.app-header.footer-menu .ant-badge.badge-item-card .ant-badge-count {
    left: 0px !important;
    right: unset !important;
    top: -30px !important;
    transform: unset !important;
    min-width: 36px !important;
    min-height: 36px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: #ff3030 !important;
}

.app-header.new-footer-menu .mobile .ant-badge.badge-item-card .ant-badge-count {
    left: 0px !important;
    right: unset !important;
    top: -24px !important;
    transform: unset !important;
    min-width: 44px !important;
    min-height: 44px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    background: #ff3030 !important;
}

.app-header.new-footer-menu .desktop .ant-badge.badge-item-card .ant-badge-count {
    left: 0px !important;
    right: unset !important;
    top: -42px !important;
    transform: unset !important;
    min-width: 44px !important;
    min-height: 44px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    background: #ff3030 !important;
}

@media screen and (min-width: 768px) {
    .app-header.footer-menu {
        height: 56px !important;
        line-height: 56px !important;
    }
}

.app-header.footer-menu .nav-tablet {
    display: flex;
    flex-direction: row;
}

.app-header.footer-menu .nav-tablet > div {
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 !important;
}

.app-header.footer-menu .nav-left ul li span,
.app-header.footer-menu .nav-right ul li span {
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-color);
    padding-left: 5px;
}

.app-header.footer-menu .nav-left.nav-left-mobile ul li,
.app-header.footer-menu .nav-right.nav-right-mobile ul li {
    display: grid;
}

@media screen and (max-width: 390px) {
    .app-header.footer-menu .nav-left.nav-left-mobile ul li,
    .app-header.footer-menu .nav-right.nav-right-mobile ul li {
        padding: 4px;
    }
}

.app-header.footer-menu .nav-left.nav-left-mobile ul li svg,
.app-header.footer-menu .nav-right.nav-right-mobile ul li svg {
    align-self: center;
    justify-self: center;
}

.app-header.footer-menu .nav-left.nav-left-mobile ul li p,
.app-header.footer-menu .nav-right.nav-right-mobile ul li p {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: var(--primary-color);
    margin-bottom: 0;
    line-height: 1;
    text-align: center !important;
}

.app-header.footer-menu .nav-left ul li,
.app-header.footer-menu .nav-center ul li,
.app-header.footer-menu .nav-right ul li {
    display: flex;
    align-items: center;
}

.app-header.footer-menu .nav-center ul li:first-child.hodai-time-cart {
    position: absolute;
}

.app-header.footer-menu .nav-center ul li:first-child {
    position: absolute;
    transform: translateY(-18px);
}

.app-header.footer-menu .nav-center li:first-child .ant-badge-count {
    top: 10px;
    right: 10px;
    min-width: 35px;
    height: 35px;
    padding: 7px 0px;
    font-size: 18px;
    border-radius: 50%;
}

.nav-left .table-header-padding-0 {
    padding: 0px !important;
}

.nav-left .table-header-padding-0 span {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 1px !important;
}

.nav-left .table-header-item-order {
    padding: 0 3px !important;
}

.nav-left .table-header-item-order span {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 2px !important;
}

.ant-notification-topRight {
    top: 40% !important;
}

.order-notification-success {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

.order-notification-success .ant-notification-notice-message {
    margin-left: 40% !important;
}

.order-notification-success .ant-notification-notice-description {
    text-align: center !important;
}

.table-header-right {
    margin-left: initial !important;
    padding: 0 !important;
}

.table-header-right .hodai-time-icon-hodai,
.table-header-right .hodai-time-icon-bill {
    position: relative;
    transform: translateX(10px);
}

.table-header-right ul li .nav-icon {
    font-size: 2rem;
}

.table-header-right .cart-top {
    margin-bottom: 10px !important;
    right: 15px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid orange;
    background-color: orange;
}

.table-header-right li:last-child .ant-badge-count-sm {
    min-width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    line-height: 19px !important;
}

.table-header-right .cart-top:hover {
    background-color: orange !important;
}

.table-header-right .cart-top .badge-item-card {
    right: 8px;
    bottom: 5px !important;
}

.table-header-right .cart-top .badge-item-card .ant-badge-count-sm {
    font-size: 17px !important;
}

.icon-modal-error {
    font-size: 24px;
    color: var(--primary-color) !important;
    padding: 0.25rem;
    margin-right: 0.25rem;
}

.overlay-card-call-service-icon {
    z-index: 100;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@media screen and (max-width: 600px) {
    .overlay-card-call-service-icon svg {
        width: 25px;
        height: 25px;
    }
    .overlay-card-call-service-icon.call-service-and-call-pay-bill svg:first-child {
        right: 35px !important;
        top: 10px;
    }
}

.overlay-card-call-service-icon.call-service-and-call-pay-bill svg:first-child {
    right: 60px;
    top: 10px;
}

.overlay-card-call-service-icon svg > g > path {
    fill: var(--primary-color) !important;
    stroke: var(--primary-color) !important;
}

.overlay-card-call-service-icon svg path {
    fill: var(--primary-color) !important;
    stroke: var(--primary-color) !important;
}

.table-card-extra-is-used .icon-bill > path {
    fill: var(--primary-color) !important;
    stroke: var(--primary-color) !important;
}

.overlay-card-call-service-icon .icon-bell,
.overlay-card-call-service-icon .icon-bill {
    position: absolute;
    top: 10px;
    right: 5px;
}

.icon-modal-call-service {
    font-size: 24px;
    color: orange !important;
    padding: 0.25rem;
    margin-right: 0.25rem;
}

.text-modal-error {
    line-height: 35px;
}

.drawer-card-detail .ant-drawer-content-wrapper {
    width: 100% !important;
}

.drawer-card-detail .ant-drawer-content-wrapper .ant-drawer-body {
    padding: 0 !important;
}

.drawer-card-detail .ant-drawer-content-wrapper .ant-drawer-body .header-cart-detail {
    padding: 24px !important;
}

.drawer-card-detail .card-detail-row {
    font-size: 14px;
    margin: 2rem 0rem;
}

.drawer-card-detail .card-detail-row-1-col-1 {
    font-weight: bold;
}

.drawer-card-detail .card-detail-row-2-col-1 {
    font-weight: 300;
}

.drawer-card-detail .card-detail-row-1-col-2 {
    text-align: center;
}

.drawer-card-detail .card-detail-row-1-col-3 {
    font-weight: bold;
    display: flex;
    flex-direction: row-reverse;
}

.drawer-card-detail .card-detail-row-1-col-4 .nav-close {
    text-align: end !important;
    color: orange !important;
}

.drawer-card-detail .card-detail-row-1-col-4 .nav-close span {
    font-size: 20px !important;
}

.modal-footer-order {
    height: 600px;
    position: relative;
}

@media screen and (min-width: 768px) {
    .modal-footer-order {
        height: 800px;
    }
}

.modal-footer-order .ant-modal-content {
    border-radius: unset;
    max-height: 100%;
    overflow: auto;
    padding: 10px;
}

.modal-footer-order .ant-modal-content .ant-modal-body h3,
.buffet-modal .ant-modal-content .ant-modal-body h3 {
    font-weight: 600;
    color: black;
    font-size: 18px;
}

@media screen and (min-width: 768px) {
    .buffet-modal .ant-modal-content .ant-modal-body h3,
    .modal-footer-order .ant-modal-content .ant-modal-body h3 {
        font-size: 22px;
    }
}

.modal-footer-order .ant-modal-footer {
    display: block;
    width: 100%;
    text-align: unset;
    padding: 0;
    border-radius: unset;
    border-top: unset;
}

.modal-footer-order .ant-modal-footer .modal-footer-order-row {
    font-weight: 600;
    color: black;
}

.modal-footer-order .ant-modal-footer .modal-footer-order-btn button {
    letter-spacing: 2px;
    font-weight: 600;
    min-width: 150px;
    font-size: 16px;
    line-height: 1;
}

@media screen and (min-width: 768px) {
    .modal-footer-order .ant-modal-footer .modal-footer-order-btn button {
        font-size: 20px;
    }
}

.modal-footer-order .ant-modal-footer .ant-row {
    justify-content: space-between !important;
}

.modal-footer-order .ant-modal-body {
    padding: 5px 0px;
}

.app-content.app-content-config {
    margin-top: 0 !important;
    padding: 8px !important;
}

.app-header-avt .ant-menu-item-only-child {
    line-height: 60px !important;
}

.app-header-height {
    height: 60px !important;
}

.nav-right-avt {
    padding: 0 !important;
    margin-left: 0 !important;
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-right-avt .ant-select-arrow {
    display: none;
}

.nav-right-avt .ant-select-selector {
    background-color: rgba(255, 255, 255, 0.6) !important;
}

.nav-right.nav-right-avt svg {
    margin-right: 15px;
}

.nav-left-name {
    line-height: 60px !important;
}

.nav-left-name span {
    margin-right: -10px;
    color: #fff;
}

.nav-left-name .restaurant-name {
    display: flex;
    padding: 0 16px;
}

.nav-left-name .restaurant-name .logo h1 {
    color: #fff;
}

.restaurant-name ul li {
    top: -1px !important;
    left: -17px;
}

.nav-left-name .restaurant-name span {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
}

.overlay-card-food-text.inactive {
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
    font-size: 26px;
    font-weight: bold;
    color: black;
}

.overlay-card-food-text.active {
    display: none;
}

.overlay-card-food.inactive {
    position: relative;
    text-align: center;
    background-color: #ccc !important;
    opacity: 0.5;
}

.footer {
    align-items: flex-start !important;
}

.footer.footer-config {
    z-index: 999;
    background-color: #fff;
    position: fixed;
    margin: unset;
    left: 0;
    bottom: 0;
    width: 100%;
}

.footer.footer-config .footer-config-btn-active {
    line-height: 55px;
    font-size: 1rem;
    font-weight: bold;
}

.footer.footer-config .footer-config-btn-inactive {
    line-height: 55px;
    font-size: 1rem;
}

.footer.footer-config .footer-config-divider {
    height: 30px;
    background-color: black;
    margin-left: 16px;
    align-self: center;
}

.footer.footer-config .footer-config-price {
    line-height: 55px;
    font-weight: bold;
    font-size: 1.25rem;
    margin-left: 16px;
}

.footer .footer-left {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid orange;
    background-color: orange;
    margin-left: 16px;
    margin-top: 5px;
}

.footer .footer-left .nav-icon {
    font-size: 2rem;
}

.drawer-history-order-table .ant-drawer-content-wrapper {
    width: 100% !important;
}

@media screen and (min-width: 768px) {
    .drawer-history-order-table .ant-drawer-content-wrapper {
        width: 60% !important;
    }
}

.drawer-history-order-table .ant-drawer-body h4 {
    margin: 0 auto;
    font-weight: 600;
    color: black;
    margin-bottom: 10px;
}

.drawer-history-order-table .ant-drawer-body .detail-item-container {
    font-weight: 600;
}

.drawer-history-order-table .ant-drawer-body .detail-item-container:last-child hr:last-child {
    display: none;
}

.drawer-history-order-table .ant-drawer-body .detail-item-container .detail-item-table-name span,
.drawer-history-order-table .ant-drawer-body .detail-item-container .detail-item-food-name span,
.drawer-history-order-table .ant-drawer-body .detail-item-container .detail-item-food-quantity span,
.drawer-history-order-table .ant-drawer-body .detail-item-container .detail-item-food-time span {
    color: black;
}

.drawer-history-order-table .ant-drawer-body .detail-item-container .detail-item-table-name,
.drawer-history-order-table .ant-drawer-body .detail-item-container .detail-item-food-name {
    justify-content: start;
    align-items: center;
    display: flex;
}

.drawer-history-order-table .ant-drawer-body .detail-item-container .detail-item-food-quantity {
    justify-content: center;
    align-items: center;
    display: flex;
}

.drawer-history-order-table .ant-drawer-body .detail-item-container .detail-item-food-time {
    justify-content: end;
    align-items: center;
    display: flex;
}

.app-header-wrapper.app-header-hodai-time .nav-left .ant-menu-horizontal,
.app-header-wrapper.app-header-hodai-time .nav-right .ant-menu-horizontal {
    display: flex;
}

.app-header-wrapper.app-header-hodai-time .nav-left .ant-menu-horizontal .ant-menu-item-only-child,
.app-header-wrapper.app-header-hodai-time .nav-right .ant-menu-horizontal .ant-menu-item-only-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-modal-footer {
    align-items: center;
    gap: 10px;
}

.order-notification {
    background-color: rgba(0, 0, 0, 0.85) !important;
    color: #fff !important;
    padding: 5px !important;
    width: 100% !important;
}

.top-overlay__content {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: 0;
}

.order-notification:last-child {
    margin-bottom: 65px !important;
}

.ant-notification {
    right: 40dvw !important;
    left: 40dvw !important;
    margin: 0 !important;
}

.ant-notification-notice-description {
    text-align: center;
}

.order-notification .ant-notification-notice-content > div {
    display: block !important;
}

.order-notification .ant-notification-notice-content > div {
    display: flex;
    align-items: center;
}

.order-notification .ant-notification-notice-content > div .ant-notification-notice-description {
    font-weight: 700;
}

.order-notification .ant-notification-notice-content > div .ant-notification-notice-message {
    margin: 0;
}

@media screen and (max-width: 768px) {
    .order-notification {
        margin-bottom: 70px !important;
    }

    .ant-notification {
        right: 30vw !important;
        left: 30vw !important;
        margin: 0 !important;
    }

    .ant-modal {
        max-height: 70vh !important;
    }
}

.ant-modal {
    top: 0 !important;
    padding: 0px !important;
    max-height: 80vh !important;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    position: relative !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: none !important;
}

.modal-food-order-wrapper {
    height: 90vh !important;
    max-height: 90vh !important;
    margin: 0 !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

.ant-modal-content {
    padding: 10px !important;
    width: 100% !important;
}

.ant-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-menu .app-header-wrapper .nav-left {
    width: 40% !important;
}

.footer-menu .app-header-wrapper .nav-center {
    flex-grow: 1 !important;
}

.footer-menu .app-header-wrapper .nav-center li {
    padding: 0 !important;
}

.footer-menu .app-header-wrapper .nav-right {
    width: 40% !important;
}

.footer-menu .app-header-wrapper .nav-left ul,
.footer-menu .app-header-wrapper .nav-right ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 767px) {
    .footer-menu .app-header-wrapper .nav-center > li {
        transform: translateY(-16px);
    }
}

.overlay {
    background-color: rgba(0, 0, 0, 0.45);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.table-cover__code .wrapper {
    position: relative;
    z-index: 2;
}

.table-cover__code {
    position: relative;
}

svg > path {
    fill: var(--primary-color) !important;
}

.new-modal-food-order-mobile__wrapper svg > path {
    fill: #f44336 !important;
}

.footer-menu .nav-left ul li:first-child svg > path {
    stroke: var(--primary-color) !important;
}

.footer-menu .nav-right g[mask='url(#mask3_305_10)'] path {
    stroke: var(--primary-color) !important;
}

.modal-food-order-container .modal-food-order-minus svg g:last-child path {
    fill: white !important;
}

.modal-food-order-container .modal-food-order-plus svg g:last-child path {
    fill: white !important;
}

.ant-modal-content svg.modal-food-order-minus-icon g:last-child path {
    /* fill: white !important; */
}

.app-header-height .nav svg path:last-child {
    fill: white !important;
}

.table-card-extra-is-used svg path {
    fill: white !important;
}

body {
    background-color: var(--secondary-color) !important;
}

.ant-modal-footer button:last-child:hover {
    background-color: #ededed !important;
}

.ant-modal.buffet-modal {
    height: 100% !important;
}

.buffet-modal .ant-modal-content {
    height: 100%;
    overflow: auto;
}
.buffet-modal {
    min-width: 90vw;
}

.buffet-modal .ant-modal-footer {
    justify-content: end;
    padding-left: 0;
    padding-right: 0;
}

.buffet-modal .ant-modal-body {
    padding: 0px;
}

.buffet-modal__left {
    flex-grow: 0.4;
    border-right: 1px solid #ccc;
    padding: 20px;
}

.buffet-modal__right {
    flex-grow: 1;
    padding: 20px;
    max-height: 50vh;
    overflow: auto;
}

.buffet-modal__right table {
    margin-top: 20px;
    width: 100%;
    text-align: center;
}

.buffet-modal__right table td {
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .buffet-modal__right table thead td:first-child {
        text-align: start;
    }
}

.buffet-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.buffet-item * {
    margin: 0;
}

.buffet-item input {
    width: 42px;
    height: 42px;
    border-color: red;
}

.buffet-item h3 {
    width: 200px;
}

.buffet-modal .ant-modal-footer {
    border-top: none;
}

@media screen and (max-width: 768px) {
    .buffet-modal .buffet-modal__right {
        border-top: 1px solid var(--primary-color);
    }
    .buffet-modal .buffet-modal__left {
        border: none;
    }
}

.buffet-item input {
    width: 100px !important;
    border: 1px solid var(--primary-color);
    text-align: center;
    padding: 5px 0;
    font-size: 20px;
    font-weight: 900;
}

.buffet-item > div {
    display: flex;
    gap: 10px;
}

.buffet-detail {
    display: flex;
    align-items: center;
}

.buffet-detail__left {
    flex-grow: 1;
    padding: 5px;
    margin-right: 20px;
}

.buffet-detail__left p {
    display: flex;
    justify-content: space-between;
}

.buffet-modal__categories {
    overflow: auto;
    max-height: 400px;
}

.buffet-modal__button {
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
}

@media screen and (max-width: 800px) {
    .buffet-item input {
        width: 30px;
        height: 30px;
    }

    .buffet-item h3 {
        width: 100px;
        font-size: 15px !important;
    }

    .buffet-item h4 {
        font-size: 12px !important;
    }

    .buffet-modal .ant-modal-body {
        padding: 0px !important;
    }

    .buffet-modal {
        min-width: 95%;
    }
}

@media screen and (max-width: 600px) {
    .buffet-modal .ant-modal-body {
        display: block;
    }

    .buffet-modal__left {
        flex-grow: 0.4;
        border-right: none;
        border-bottom: 1px solid #ccc;
        margin-top: 20px;
    }

    .buffet-modal__left h4 {
        font-weight: 600;
    }

    .buffet-modal__button {
        display: flex;
        justify-content: flex-end;
    }

    .buffet-modal__left {
        padding: 0;
    }

    .buffet-modal__right {
        padding: 0;
    }
}

.ant-modal-content {
    border-radius: 0 !important;
}

.ant-modal-confirm-title {
    color: var(--primary-color) !important;
    text-align: center;
    font-weight: 700 !important;
    font-size: 20px !important;
}

.ant-modal-confirm-content {
    text-align: center;
    font-size: 18px !important;
}

.ant-modal-confirm-btns button:last-child {
    background: linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%) !important;
    border-radius: 30px !important;
    border: none !important;
    padding: 5px 30px !important;
}

.page-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.page-404 .content {
    width: 400px;
}

.page-404 .content img {
    width: 100%;
}

.notification-expire-handler {
    margin-bottom: 36px !important;
}

.sub-category-modal .ant-modal-body .sub-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.sub-category-modal .ant-modal-body h1 {
    text-align: center;
}

.sub-category {
    background-color: var(--primary-color);
    color: #fff;
    width: calc(50% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border-radius: 9px;
}

.sub-category h2 {
    color: #fff;
}

.table {
    padding: 10px;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    aspect-ratio: 1/1;
}

@media screen and (max-width: 600px) {
    .number-user h4 {
        font-size: 14px !important;
    }
    .number-user svg {
        height: 14px;
        width: 14px;
    }

    .table {
        padding: 5px;
    }
}

.table.table-card-extra-is-used h2,
.table.table-card-extra-is-used h5,
.table.table-card-extra-is-used h4 {
    color: #fff !important;
}

.table .table__row h2 {
    text-align: start;
    font-size: 12px;
}

@media screen and (max-width: 600px) {
    .table .table__row h2 {
        text-align: start;
        font-size: 13px;
    }

    .table h5 {
        font-size: 13px !important;
    }
}

.table .table__row:nth-child(2) {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table .table__row.last {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.table-card-extra svg > g > path {
    fill: var(--primary-color) !important;
}

.table .number-user {
    display: flex;
    align-items: center;
    gap: 1px;
}

.table .number-user h4 {
    margin-bottom: 0;
}
.table .table__row h5 {
    margin-bottom: 0;
}

.sub-wrapper {
    max-height: 50vh !important;
    overflow: auto;
}

.sub-categories {
    height: 100%;
    width: 100%;
    padding-right: 10px;
}

.sub-wrapper::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.sub-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.sub-wrapper::-webkit-scrollbar-thumb {
    background: #888;
}

.sub-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.sub-wrapper::-webkit-scrollbar {
    display: block;
}

.mobile-nav-menu-new {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100dvh - 100px);
}

.ant-drawer-content-wrapper {
    height: auto !important;
}

.ant-drawer-content {
    height: fit-content !important;
}

.ant-drawer-body {
    height: fit-content !important;
}

.ant-drawer-wrapper-body {
    height: fit-content !important;
}

.ant-modal.modal-confirm-input-number-people .ant-modal-footer h3.customer {
    flex-grow: 1;
}

.ant-modal.modal-confirm-input-number-people .ant-modal-footer h3.customer .ant-input-number {
    width: 100%;
}

.customer-app-header {
    background-color: var(--primary-color);
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    position: relative;
}

.customer-app-header .btn-logout {
    position: absolute;
    left: 3%;
    font-weight: 500;
    background: 'linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%)';
}

.customer-app-header h1 {
    color: #fff;
    margin: 0;
}

.ant-tabs-nav-wrap {
    justify-content: center;
}

.radio-button-type {
    display: block !important;
}

.modal-food-order-option {
    background-color: rgb(249, 248, 248);
}

.option-food-group {
    width: 100%;
}

.option-food-row {
    width: 100%;
    flex-direction: column !important;
    gap: 5px !important;
}

.new-modal-food-order-summary__col-1 {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 6px 8px;
}

.ant-drawer .ant-drawer-content-wrapper {
    transition-duration: 1s !important ;
}

.new-modal-food-order-quantity {
    display: flex !important;
    align-items: center;
    justify-content: left;
}

.new-modal-customer-order-history__button,
.new-modal-food-order-submit {
    display: flex !important;
    align-items: center;
    justify-content: right;
}

.new-child-lv1-customer-order-drawer__wrapper .ant-drawer-body {
    padding-bottom: 22px !important;
}

.new-child-lv1-customer-order-drawer__wrapper .ant-drawer-content-wrapper {
    position: absolute;
    bottom: 0;
    width: 100% !important;
}

.new-child-lv2-customer-order-drawer__wrapper .ant-drawer-content-wrapper {
    position: absolute;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
}

.new-child-lv1-customer-order-drawer__wrapper .close-icon svg > path {
    fill: red !important;
}

.new-modal-customer-order-history__button button,
.new-modal-food-order-submit button {
    background: linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%) !important;
    border-radius: 25px !important;
    display: flex;
    align-items: center;
}

.new-modal-food-order-submit button span {
    width: 100%;
    text-align: center;
    font-size: 15px !important;
}

.new-modal-customer-order-history-lv3__button-content {
    width: 100%;
    height: 48px;
    border-radius: 25px;
    background: #b3b3b3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-modal-customer-order-history-lv3__button-content span {
    color: white;
}

.new-modal-customer-order-history-lv3__button button {
    min-height: 3.5em;
    background-color: #ededed !important;
    border-radius: 8px !important;
    padding: 16px 56px;
    display: flex;
    align-items: center;
}

.new-modal-customer-order-history-lv3__button button span {
    color: white !important;
}

.new-modal-customer-order-history__button button span {
    width: 100%;
    font-size: 15px !important;
}

.new-modal-food-order-summary__col-2 {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
    text-align: end;
    justify-content: center;
}

.option-food-row .ant-radio-wrapper {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between !important;
    padding: 5px 0 8px 0;
    border-bottom: 1px solid rgb(234, 234, 234);
}

.option-food-row .ant-radio-wrapper span:last-child {
    flex-grow: 1;
}

.new-option-food-row .new-option-food-row__topping {
    width: 100%;
    justify-content: space-between !important;
    padding: 4px 12px;
    background-color: #e8e8e8;
    border-radius: 8px;
    margin-bottom: 10px;
}

.new-option-food-row .new-option-food-row__topping .ant-checkbox-wrapper-checked .ant-checkbox,
.new-option-food-row .new-option-food-row__topping .ant-checkbox-wrapper-checked {
    color: unset !important;
}

.new-option-food-row .new-option-food-row__topping .ant-checkbox-wrapper-checked .ant-checkbox .ant-checkbox-inner {
    background-color: unset !important;
    border-color: unset !important;
    border: unset !important;
}

.new-option-food-row .new-option-food-row__topping .ant-checkbox-wrapper-checked .ant-checkbox .ant-checkbox-inner::after {
    display: none !important;
}

.new-option-food-row .new-option-food-row__topping .ant-checkbox-wrapper .ant-checkbox {
    position: relative;
    top: 2px;
}

.new-option-food-row .new-option-food-row__topping .ant-checkbox-wrapper .ant-checkbox-inner {
    border-radius: 50% !important;
    width: 24px !important;
    height: 24px !important;
}

.new-option-food-row__topping .inactive {
    display: none !important;
}

.new-option-food-row__topping .active {
    display: block;
}

.new-option-food-row .new-option-food-row__options {
    display: flex;
    align-items: center;
}

.new-option-food-row .ant-radio-wrapper {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between !important;
    padding: 15px 10px 15px 5px;
    background-color: #f2f2f2;
    border-radius: 8px;
}

.new-option-food-row .ant-radio-wrapper .ant-radio {
    position: relative;
    top: 0;
}

.new-option-food-row .ant-radio-wrapper span:last-child {
    flex-grow: 1;
    width: 24px !important;
    min-height: 24px !important;
    max-height: 100%;
}

.ant-radio-checked .ant-radio-inner {
    border-color: rgb(255, 140, 0) !important;
}

.ant-radio-inner::after {
    background-color: rgb(255, 140, 0) !important;
}

.option-food-container:last-child {
    margin-bottom: 15px !important;
}

.modal-food-order-wrapper .modal-food-order-option {
    gap: 10px;
}

.new-customer-order__top {
    position: relative;
    display: table-cell;
    width: 100%;
}

.option-food-title {
    padding-bottom: 10px;
    border-bottom: 1px dashed lightgray;
    margin-left: 2px;
}

.option-food-title h5 {
    margin: 0 !important;
}

.option-food-title h5:first-child {
    color: var(--primary-color);
}

.option-food-row .row {
    width: 100%;
    padding: 5px 8px;
    border-bottom: 1px solid rgb(234, 234, 234);
    align-items: center;
    text-transform: capitalize;
}

.new-option-food-row button,
.option-food-row button {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 140, 0);
    border: none;
    color: #fff;
    border-radius: 50%;
    padding-left: unset !important;
    padding-right: unset !important;
}

.new-option-food-row button.disable,
.option-food-row button.disable {
    background-color: rgba(255, 140, 0, 0.5);
    pointer-events: none;
}

.option-food-name.topping {
    border-bottom: 1px dashed lightgray;
    padding-bottom: 10px;
}

.option-food-row__info p {
    text-align: center !important;
}

.nav-category-bar.nav-category-bar-parent .ant-btn {
    border-radius: 30px;
    padding: 5px 20px !important;
}

.nav-category-bar.nav-category-bar-parent {
    gap: 10px;
    font-size: 10px !important;
    padding: 5px !important;
}

.nav-category-bar.nav-category-bar-parent span {
    font-size: 17px !important;
}

.nav-category-bar.nav-category-bar-child .ant-btn span {
    color: #000 !important;
    font-size: 15px !important;
}

.nav-category-bar.nav-category-bar-child .ant-btn.child-active {
    border-bottom: 5px solid var(--primary-color) !important;
}

.nav-category-bar.nav-category-bar-child .ant-btn.child-active span {
    color: var(--primary-color) !important;
}

.nav-category-bar.nav-category-bar-child {
    box-shadow: 0px 3px 20px 0px #d2d2d2;
}

.ant-modal-content {
    border-radius: 10px !important;
}

.footer-menu {
    box-shadow: -1px -5px 20px 0px rgba(0, 0, 0, 0.15) !important;
}

.description-value {
    margin-bottom: 0 !important;
    font-size: 12px !important;
    color: rgb(212, 226, 231);
}

.table-tag {
    background-color: var(--primary-color);
    position: fixed;
    bottom: 80px;
    left: 0;
    padding: 10px 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    font-weight: 700;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.3) !important;
}

.new-food-order-drawer__wrapper .ant-drawer-body {
    display: flex;
    flex-direction: column;
    height: 100% !important;
}

.new-food-order-drawer__wrapper {
    z-index: 9999 !important;
    font-family: 'Noto Sans Light', sans-serif !important;
}

.new-food-order-drawer__wrapper .ant-drawer-wrapper-body {
    height: inherit !important;
}
.new-food-order-drawer__wrapper .ant-drawer-content-wrapper {
    height: inherit;
    max-height: 90% !important;
}

.new-food-order-drawer__wrapper.over-flow .ant-drawer-content-wrapper {
    height: auto !important;
}

.new-food-order-drawer__wrapper .ant-drawer-content {
    height: 100% !important;
}

.ant-drawer-body {
    padding: 13px !important;
}

.new-food-order-drawer__wrapper .ant-drawer-content,
.new-child-lv1-customer-order-drawer__wrapper .ant-drawer-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.new-modal-food-order__options-wrapper-mobile.hidden {
    display: none !important;
}

.new-customer-order__top-wrapper,
.new-customer-order__bottom-wrapper {
    display: table-row;
    height: 0px;
}

.new-customer-order__wrapper {
    z-index: 9999 !important;
}

.new-child-lv1-customer-order-drawer__wrapper {
    z-index: 10000 !important;
}

.new-child-lv2-customer-order-drawer__wrapper {
    z-index: 10001 !important;
}

.new-customer-order__wrapper .ant-drawer-content-wrapper {
    height: 100% !important;
    width: 100% !important;
}

.new-customer-order__wrapper .ant-drawer-wrapper-body,
.new-customer-order__wrapper .ant-drawer-content {
    height: 100% !important;
}

.new-customer-order__wrapper .ant-drawer-body {
    padding: unset !important;
}
.new-customer-order__wrapper .lazy-load-image-background {
    height: 100% !important;
    display: flex !important;
    align-items: center;
}

.new-customer-order__wrapper .lazy-load-image-background img {
    border-radius: 10px;
}

.choose-payment-method__box {
    position: relative;
    max-height: 18em;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid orange;
}

.choose-payment-method__box .checkbox-icon {
    position: absolute;
    right: 0;
    top: 0;
}

.choose-payment-method__box .cash-counter {
    height: 100%;
}

.choose-payment-method_img {
    max-height: 15em;
    height: 15em;
    padding-top: 2em;
    display: flex;
    justify-content: center;
}

.new-call-service__wrapper .ant-drawer-mask {
    opacity: 0 !important;
}

.new-call-service__wrapper .ant-drawer-content-wrapper .ant-drawer-content {
    background-color: white !important;
}

.new-call-service__wrapper .ant-drawer-wrapper-body .ant-drawer-body {
    padding: 9.8px !important;
}

.new-call-service__wrapper .ant-drawer-wrapper-body .ant-drawer-body,
.new-call-service__wrapper .ant-drawer-wrapper-body {
    display: flex !important;
}

.new-call-service__wrapper {
    display: flex;
}

.service-bell__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 998;
    margin: -8px;
    top: 0;
}

.search-overlay {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);
    /* height: 100%; */
    /* width: 100%; */
    z-index: 998;
    /* margin-left: -8px; */
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: hidden;
    /* padding-top: 52px; */
}

.new-cart-drawer__wrapper .inactive .inactive-wrapper,
.modal-footer-order-row .inactive .inactive-wrapper,
.service-bell .inactive .inactive-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    background-color: white;
    padding: 3px 3px;
    background-clip: content-box;
    border-radius: 30px !important ;
}

.new-call-service__wrapper .active {
    border: 1px solid white !important;
    border-radius: unset !important;
}

.service-bell .inactive {
    position: relative;
    border: 1px solid white !important;
}

.new-cart-drawer__wrapper .ant-drawer-content-wrapper {
    max-height: 40rem !important;
}

.new-cart-drawer__wrapper .inactive,
.modal-footer-order-row .inactive,
.service-bell .inactive,
.modal-footer-order-row .active,
.new-cart-drawer__wrapper .active,
.service-bell .active {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px !important ;
}

.new-cart-drawer__wrapper .active span,
.modal-footer-order-row .active span,
.service-bell .active span {
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    color: white;
}

.new-cart-drawer__wrapper .inactive span,
.modal-footer-order-row .inactive span,
.service-bell .inactive span {
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    color: black;
    position: relative;
    z-index: 1;
}

.new-child-lv2-customer-order-drawer__wrapper .ant-drawer-content {
    height: 100% !important;
    padding-bottom: 9px !important;
}

.new-child-lv2-customer-order-drawer__wrapper .ant-drawer-wrapper-body,
.new-child-lv2-customer-order-drawer__wrapper .ant-drawer-body {
    height: 100% !important;
}

.new-modal-customer-order-history__summary {
    width: 100%;
}

.new-modal-customer-order-history__summary .top,
.new-modal-customer-order-history__summary .middle,
.new-modal-customer-order-history__summary .bottom {
    display: table-row;
}

.new-modal-customer-order-history__summary .top {
    height: 10%;
}

.new-modal-customer-order-history__summary .bottom {
    height: 20%;
}

.new-modal-customer-order-history__summary .middle {
    height: 100%;
}

.new-call-service__wrapper .ant-drawer-content-wrapper {
    /* transition: unset 1s ease-in !important; */
    width: 310px !important;
    bottom: 70px;
    box-shadow: 0px 0px 20px 0px #d2d2d2 !important;
    clip-path: inset(-20px -20px -20px 0);
}

.service-bell {
    transition: transform 1s ease !important;
    position: fixed;
    width: 370px;
    height: 60px;
    right: -300px;
    bottom: 70px;
    background-color: white;
    z-index: 999;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    cursor: pointer;
    box-shadow: 0px 0px 20px 0px #d2d2d2;
    clip-path: inset(-20px 0 -20px -20px);
}

.service-bell.move {
    /* right: 369px; */
    transform: translate(-81%, 0);
    will-change: transform, opacity;
}

.service-bell .service-bell__content {
    position: absolute;
    display: flex;
    top: 16%;
    right: 0;
    width: 80%;
}

.service-hodai {
    position: fixed;
    width: 66px;
    height: 60px;
    left: 0;
    bottom: 70px;
    background-color: white;
    z-index: 996;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    cursor: pointer;
    box-shadow: 0px 0px 20px 0px #d2d2d2;
}

.service-hodai .service-hodai__wrapper,
.service-bell .service-bell__wrapper {
    position: absolute;
    top: 50%;
    transform: translate(20%, -50%);
    width: 48px;
    height: 48px;
    background: linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%) !important;
    border-radius: 50%;
}

.service-hodai .service-hodai__icon,
.service-bell .service-bell__icon {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.drawer-customer__bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%;
    z-index: 999;
    position: fixed;
    transition: height 0s ease 0.7s;
    will-change: height;
}

.drawer-customer__bottom.open {
    height: 100%;
    transition: transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 0.7s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}

.drawer-customer__mask {
    opacity: 0;
    pointer-events: none;
}

.drawer-customer__mask.open {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.45);
    z-index: 999;
    opacity: 1;
    pointer-events: auto;
}

.drawer-customer__wrapper {
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    overflow: hidden;
    width: 100%;
    transition: 1s all;
    z-index: 1000;
    transform: translateY(100%);

    max-height: 90%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    bottom: 0;

    padding: 13px;
    display: flex;
    flex-direction: column;
    text-align: start;
}

.drawer-customer__wrapper.open {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    bottom: 0;
    transform: translateY(0%);
}

.new-modal-food-order-tablet__content {
    position: relative;
}

.new-modal-food-order-tablet__wrapper {
    position: relative;
    overflow-y: auto;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.new-modal-food-order-tablet__bottom,
.new-modal-food-order-tablet__bottom--height-5rem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: sticky;
    bottom: 0;
    background-color: white;
    height: 11rem;
    padding-left: 10px;
    padding-right: 10px;
}

.new-modal-food-order-tablet__bottom--height-5rem {
    height: 5rem !important;
}

.new-modal-food-order-mobile__bottom {
    position: relative;
}

.card-food-wrapper {
    border-radius: unset;
}

.card-food-img {
    width: 100%;
    height: 25vh;
    margin: 0 auto;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.card-food-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.new-modal-food-order__container {
    contain: paint;
    margin-top: -37px;
}

.top-overlay-tablet.is-top,
.bottom-overlay-tablet.is-bottom {
    opacity: 0;
    pointer-events: none;
    transition: opacity 1.2s;
}

.top-overlay.is-top,
.bottom-overlay.is-bottom {
    opacity: 0;
    pointer-events: none;
    transition: opacity 1.2s;
}

.top-overlay-tablet.is-top .top-overlay__content svg,
.bottom-overlay-tablet.is-bottom .bottom-overlay__content svg {
    animation: unset !important;
}

.top-overlay.is-top .top-overlay__content svg,
.bottom-overlay.is-bottom .bottom-overlay__content svg {
    animation: unset !important;
}

.bottom-overlay-tablet,
.top-overlay-tablet {
    opacity: 1;
    width: 100%;
    height: 50px;
    position: fixed;
    position: -webkit-fixed;
    z-index: 1111;
}

.top-overlay-tablet {
    top: 0;
    background: linear-gradient(to bottom, #707070, transparent);
}

.bottom-overlay-tablet {
    background: linear-gradient(to top, #707070, transparent);
}

.bottom-overlay,
.top-overlay {
    opacity: 1;
    width: 100%;
    height: 50px;
    position: absolute;
    position: -webkit-absolute;
    z-index: 1111;
}

.top-overlay {
    top: 0;
    background: linear-gradient(to bottom, #707070, transparent);
}

.bottom-overlay {
    background: linear-gradient(to top, #707070, transparent);
    top: -50px;
}

.top-overlay__content,
.bottom-overlay__content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bottom-overlay__content {
    width: 100vw;
    margin-left: -13px;
}

@media only screen and (min-width: 768px) {
    .bottom-overlay__content {
        width: 100%;
    }
}

.top-overlay__content svg path,
.bottom-overlay__content svg path {
    fill: white !important;
}

.top-overlay__content svg {
    position: relative;
    animation: animateToTop 1.5s;
    animation-iteration-count: infinite;
}

.bottom-overlay__content svg {
    position: relative;
    animation: animateToBottom 1.5s;
    animation-iteration-count: infinite;
}

.new-modal-food-order__wrapper .top-overlay {
    top: 0px;
}

@keyframes animateToBottom {
    from {
        top: 0px;
    }
    to {
        top: 16px;
    }
}

@keyframes animateToTop {
    from {
        bottom: 0px;
    }
    to {
        bottom: 16px;
    }
}

.card-food-img .overlay-description {
    width: 100%;
    height: 40%;
    background: linear-gradient(to top, black, transparent);
    position: absolute;
    bottom: 0;
}

.card-food-img .overlay-description .card-food-description {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    font-weight: 600;
    font-size: 13px;
}

@media screen and (min-width: 992px) {
    .card-food-img {
        height: 25vh;
    }
}

@media screen and (min-width: 1200px) {
    .card-food-img {
        height: 16vh;
    }
}

@media screen and (min-width: 1400px) {
    .card-food-img {
        height: 18vh;
    }
}

@media screen and (min-width: 1600px) {
    .card-food-img {
        height: 20vh;
    }
}

@media screen and (min-width: 1800px) {
    .card-food-img {
        height: 22vh;
    }
}

@media screen and (min-width: 2000px) {
    .card-food-img {
        height: 25vh;
    }
}

.card-food-img img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
}

.card-food-img > span {
    height: 100%;
    width: 100%;
}
.card-food-wrapper .card-food-img img {
    border-radius: unset;
}

.card-food-wrapper .ant-card-body {
    /* padding: 0 !important; */
    padding: 2px;
}

.card-food-wrapper .ant-card-body .card-food-img img {
    border-radius: unset;
}

.card-food-wrapper .ant-card-body {
    /* height: 5vh; */
    font-weight: 600;
    font-size: 13px;
}

@media screen and (min-width: 768px) {
    .card-food-wrapper .ant-card-body {
        /* height: 4vh; */
    }
}

@media screen and (max-width: 385px) {
    .card-food-wrapper .ant-card-body {
        font-size: 11px;
    }
}

@media screen and (min-width: 768px) {
    .card-food-wrapper .ant-card-body {
        font-size: 13px;
    }
}

@media screen and (min-width: 807px) {
    .card-food-wrapper .ant-card-body {
        font-size: 14px;
    }
}

@media screen and (min-width: 992px) {
    .card-food-wrapper .ant-card-body {
        font-size: 16px;
    }
}

.card-food-wrapper .ant-card-body .card-food-name {
    display: flex;
    text-align: start;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: black !important;
}

.card-food-wrapper .ant-card-body .card-food-unit,
.card-food-wrapper .ant-card-body .card-food-price {
    display: flex;
    justify-content: end;
    -webkit-justify-content: flex-end;
}

.card-food-wrapper .ant-card-body .card-food-price {
    color: var(--primary-color);
}

.card-food-wrapper span {
    font-size: 1.1rem !important;
}

.card-food-wrapper {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card-food-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 9px !important;
}

.card-food-wrapper .card-food-img {
    height: 100%;
}

.card-food-wrapper .ant-card-cover {
    flex: 1;
    overflow: hidden;
    aspect-ratio: 3/2 !important;
}
.card-food-wrapper .ant-card-body {
    min-height: 30% !important;
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-food-wrapper .ant-card-body .ant-row {
    width: 100%;
}
@media screen and (max-width: 500px) {
    .card-food-wrapper span {
        font-size: 0.8rem !important;
    }
}

.table-detail-search {
    position: fixed;
    width: 66px;
    height: 60px;
    right: 0;
    bottom: 70px;
    background-color: white;
    z-index: 996;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    cursor: pointer;
    box-shadow: 0px 0px 20px 0px #d2d2d2;
}

.table-detail-search .table-detail-search__wrapper {
    position: absolute;
    top: 50%;
    transform: translate(20%, -50%);
    width: 48px;
    height: 48px;
    background: linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%) !important;
    border-radius: 50%;
}

.table-detail-search .table-detail-search__icon {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.table-detail-search svg path {
    fill: white !important;
}

.table-detail-search .search-glass-icon svg {
    width: 70%;
    padding-top: 6px;
}

.table-home-new-search__container {
    position: relative;
    height: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.table-home-new-search__search-input-container {
    margin: 0 auto;
    margin-top: 8px;
}

.table-home-new-search__search-content--isShowed,
.table-home-new-search__search-content {
    position: absolute;
    top: 4.5rem;
    width: 100%;
}

.table-home-new-search__search-content {
    display: none;
}

.table-home-new-search__search-content--isShowed,
.table-home-new-search__search-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.search-overlay .table-home-new-search__search-content--isShowed {
    max-width: 400px;
}

.table-home-new-search__search-content-wrapper {
    max-height: 384px;
    overflow-y: auto;
    padding-top: 8px;
    padding-bottom: 8px;
}

.search-content-slide-down {
    animation-name: slide-down;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.search-content-slide-down-hide {
    animation-name: slide-down-hide;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes slide-down {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-down-hide {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.table-home-new-search__search-action {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
}

.table-home-new-search__search-action .close-input-icon {
    position: absolute;
    right: 4rem;
}

.table-home-new-search__search-input {
    position: relative;
}

.search-overlay .table-home-new-search__search-input {
    display: block !important;
}

.table-home-new-search__search-input-container .close-input-icon {
    position: absolute;
    right: 0;
    height: 100%;
    text-align: end;
}

.table-home-new-search__search-keyboard {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
    column-count: 3;
    column-gap: 8px;
    position: absolute;
    bottom: 68px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 6px;
}

.search-overlay .table-home-new-search__search-keyboard {
    bottom: 96px;
}

.table-home-new-search__button-container {
    column-gap: 8px !important;
}

.table-home-new-search__button {
    background-color: #feeadc;
    position: relative;
    width: 100%;
    border-radius: 12px;
    flex: 0 0 calc(100% / 3 - 8px) !important;
    box-shadow: 2px 1px 2px gray;
}

.table-home-new-search__button:active {
    box-shadow: 0 0 0 white;
}

.table-home-new-search__button p {
    font-size: 36px;
    color: black;
}

.table-home-new-search__button::before {
    content: '';
    display: block;
    padding-top: 60%;
}

.table-home-new-search__button > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-home-new-search__button svg {
    height: 100%;
    width: 100%;
    padding-top: 10%;
}

.table-home-new-search__button svg > path {
    fill: black !important;
}

.plus-icon,
.search-glass-icon {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.plus-icon svg,
.search-glass-icon svg {
    width: 56% !important;
    padding-top: 6px;
    padding-left: 2px;
}

.table-home .close-input-icon svg {
    position: absolute;
    width: 1.5rem;
    right: 4rem;
    top: 50%;
    transform: translateY(-48%);
}

.table-home .close-input-icon svg path {
    fill: #ccc !important;
    cursor: pointer;
}

.table-home-new-search__search-content-box,
.table-home-new-search__search-input-container input {
    width: 100%;
    min-height: 4rem;
    outline: none;
    border: 1px solid #8a6026;
    border-radius: 26px;
    padding-left: 12px;
    padding-right: 0px;
}

.search-overlay .table-home-new-search__search-content-box {
    width: 100%;
}

.table-home-new-search__search-content-box--isShowed {
    display: none;
    visibility: hidden;
}

.table-home-new-search__search-food-name {
    color: #fb8b4b;
    font-weight: bold;
    font-size: 11px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.table-home-new-search__search-content-box {
    padding-right: 3px;
}

.table-home-new-search__search-content-box .ant-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.table-home-new-search__search-price,
.table-home-new-search__search-code {
    color: #702925;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
}

.table-home-new-search__search-price {
    text-align: end !important;
}

.table-home-new-search__search-food-info {
    padding-left: 3px;
    padding-right: 3px;
    box-shadow: rgb(231, 234, 235) 2px 0px 0px 0px inset;
}

.table-home-new-search__search-input-container input[type='search'] {
    font-size: 36px;
    color: #fb8b4b;
    font-weight: bold;
    padding: unset !important;
    text-align: center !important;
}

.table-home-new-search__search-input-container input::placeholder {
    font-size: 16px !important;
    /* transform: translateY(-4px); */
}

.table-home-new-search__search-input-container input::-webkit-input-placeholder {
    text-align: center;
}
.table-home-new-search__search-input-container input:-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
}
.table-home-new-search__search-input-container input::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
}
.table-home-new-search__search-input-container input:-ms-input-placeholder {
    text-align: center;
}

input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

.table-home-new-search__search-btn {
    width: 3.6rem;
    height: 3.6rem;
    background: linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%);
    border-radius: 50%;
}

.table-home-new-search__search-btn path {
    fill: white !important;
}

.table-home-new-search-keyboard-action {
    position: fixed;
    width: 66px;
    height: 60px;
    right: 0;
    bottom: 70px;
    background-color: white;
    z-index: 996;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    cursor: pointer;
    box-shadow: 0px 0px 20px 0px #d2d2d2;
}

.table-home-new-search-keyboard-action .table-home-new-search-keyboard-action__wrapper {
    position: absolute;
    top: 50%;
    transform: translate(20%, -50%);
    width: 48px;
    height: 48px;
    background: linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%) !important;
    border-radius: 50%;
}

.table-home-new-search-keyboard-action .table-home-new-search-keyboard-action__icon {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

.keyboard-icon {
    height: 100%;
    width: 100%;
}

.keyboard-icon svg path {
    fill: white !important;
}

.keyboard-icon svg {
    width: 70%;
    padding-top: 8px;
}

.search-overlay__close {
    width: 100%;
    height: 4rem;
    position: relative;
    top: 0;
    right: 0;
    z-index: 999;
}

.search-overlay__close .close-input-icon {
    height: 100%;
    width: 4rem;
    cursor: pointer;
    position: absolute;
    right: 0;
}
.search-overlay__close .close-input-icon svg path {
    fill: var(--primary-color) !important;
}

.table-home-new-search__search-input input::placeholder {
    color: orange;
    font-size: 32px;
}

.button-gradient {
    background: linear-gradient(99deg, rgb(255, 166, 0) 14.7%, rgb(255, 99, 97) 73%) !important;
    min-width: 144px !important;
}
