.order-screen__header,
.order-screen__footer {
    background-color: var(--primary-color);
}

.order-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    padding: 15px;
}

.order-screen__header h1,
.order-screen__footer h1 {
    color: #fff;
    font-size: 25px;
}

.order-screen h2 {
    font-size: 40px;
    margin: 0;
}

.order-detail {
    height: 100%;
}

.order-detail .list {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.order-detail .list::-webkit-scrollbar {
    display: block;
}

/* width */
.order-detail .list::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.order-detail .list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.order-detail .list::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.order-detail .list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

table {
    width: 100%;
}

table td {
    width: 50%;
    padding: 10px 40px;
    font-size: 18px;
    border: 2px solid #000;
    font-weight: 700;
}

table tr:last-child td {
    background-color: var(--primary-color-80);
    color: #fff;
}
.price {
    padding: 10px 0;
    align-items: center;
}

.box {
    background-color: var(--primary-color);
    height: 40px;
    border-radius: 5px;
    color: #fff;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.row-detail * {
    margin: 0 !important;
}

h4,
p {
    text-align: start !important;
}

@media screen and (max-width: 600px) {
    .price {
        padding: 10px 10px;
    }
}
@media screen and (max-width: 500px) {
    .price img {
        width: 120px;
    }
    table td {
        width: 50%;
        padding: 10px 30px;
        font-size: 15px;
    }
}

@media screen and (max-width: 400px) {
    table td {
        width: 50%;
        padding: 10px 10px;
        font-size: 15px;
    }
}
.home-img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center;
}

.done-table td {
    padding: 20px 20px;
    font-size: 35px;
}

.inprocess-table td {
    padding: 20px 0;
    font-size: 20px;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    height: 100%;
}

.slide {
    display: inline-block;
    height: 100%;
    width: 100%;
}

.text-fluid p:first-child,
.text-fluid p:nth-child(2) {
    font-size: 7vw;
}

.text-fluid p:nth-child(3) {
  font-size: 4vw;

}

.text-large {
  font-size: 7vw;
}

.text-medium {
  font-size: 4vw;
}

.text-small {
  font-size: 3.5vw;
}