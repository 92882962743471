@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Noto Sans Light';
    src: local('NotoSansJP-Light'), url('./assets/fonts/NotoSansJP-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Noto Sans Bold';
    src: local('NotoSansJP-Bold'), url('./assets/fonts/NotoSansJP-Bold.ttf') format('truetype');
}

body {
    margin: 0;
    font-family: 'Crimson Text', serif;
    font-family: 'Noto Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* position: fixed; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
    overscroll-behavior: none;
}