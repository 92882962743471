@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');

.kitchen{
    display: flex;
    flex-direction: column;
}

.kitchen *{
    font-family: "Noto Sans JP", sans-serif;
}

.kitchen__body{
    flex-grow: 1;
}

.kitchen__header{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.kitchen__body .title{
    display: flex;
    justify-content: center;
}

.kitchen__body .title span{
    width: calc(100% / 3);
    font-size: 20px;
    /* font-weight: 700; */
    border-bottom: 2px solid #b4b4b4;
    /* padding: 15px 10px; */
}

.kitchen__body .title span:nth-child(2), .content__item:nth-child(2){
    border: 2px solid rgb(180, 180, 180);
    border-top: none;
}

.kitchen__body .content{
    display: flex;

}

.content__item{
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    width: calc(100% / 3);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}

.content__item:nth-child(2){
    border-bottom: none;
}

.order-item {
    background-color: #f3a118;
    border-radius: 7px;
    cursor: pointer;
    padding: 5px 10px;
    -webkit-user-select: none;
    user-select: none;
}

.order-item.danger{
    background-color: #ff3130 !important;
}

.order-item.danger *{

    color: #fff !important;
}

.order-item__title{
    border-bottom: 1.5px solid #000;
    color: #000;
    display: flex;
    font-size: 20px;
    font-weight: 700;
    justify-content: space-between;
}

.order-item__content{
    display: flex;
    gap: 15px;
    padding: 10px 0px 0px 0px;
}

.order-item__content .left{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #000;
}

.order-item__content .left h1{
    font-size: 50px;
}

.order-item__content .right .detail{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.order-item__content .right h3{
    margin: 0;
    text-align: left;
}

.order-item__content .right span{
    margin-left: 10px;
    text-align: start;
}

.content__item:nth-child(1) .order-item{
    background-color: #f3a118;
}

.content__item:nth-child(2) .order-item{
    background-color: #71ccff;
}

.content__item:nth-child(3) .order-item{
    background-color: #42b254;
}

.history{
    background-color: #000;
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 20px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 11px 2px rgb(0 0 0);
    cursor: pointer;
}
.history svg > path{
    fill: #fff !important;
}

.history-modal{
    height: 80vh;
}

.history-modal .ant-modal-body{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.history-modal .ant-modal-content{
    height: 100%;
    max-height: 100%;
    overflow: auto;
    scroll-margin: 20px;
}

.close{
    width: 100%;
    aspect-ratio: 1/1;
    background-color: #73cafb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.close i{
    font-size: 30px;
}
.close .icon{
    border: 3px solid #2f4ed7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50%;
}

.close .icon svg > path{
    fill: #2f4ed7 !important;
}

.kitchen__header{
    height: 50px;
}

.kitchen__header .logo{
    font-size: 30px;
    font-weight: 900;
}

.kitchen__header select{
    padding: 5px 20px;
    font-size: 20px;
}

.kitchen .setting .avatar{
    background-color: #304cdb;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.kitchen .setting .avatar span{
    font-size: 15px;
    color: #fff;
}

.history-modal .order-item{
    background-color: #d9d9d9;
}

.setting{
    display: flex;
    align-items: center;
    gap: 20px;
}

.setting .ant-select{
    width: fit-content !important;
}

.setting .ant-select .ant-select-arrow{
    display: none;
}

.setting .ant-select-selection-item{
    padding: 0 !important;
    
}

.setting .ant-select-selection-item img{
    margin: 0 !important;
}

.setting button{
    border-radius: 10px;
    padding: 5px 15px;
    border: 1px solid #000;
    cursor: pointer;
}

.dropdown-language {
    padding-right: 2px !important;
    background: #f4f6fd !important;
    border: #2f4cdd solid 1px !important;
    padding: 10px 0px !important;
    padding-left: 15px !important;
}